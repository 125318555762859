import React from "react";
import { useTranslation } from "react-i18next";
import {Dropdown, DropdownButton} from "react-bootstrap";

import SizeDropdownProps from "./SizeDropdown.types";
import "./SizeDropdown.scss";

function SizeDropdown({size, setSize}: SizeDropdownProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <div id={"size-dropdown"}>
            <DropdownButton id={"size"} title={size || t("filters.size")}>
                <Dropdown.Item onSelect={() => setSize(10)} active={size === 10}>
                    10
                </Dropdown.Item>
                <Dropdown.Item onSelect={() => setSize(30)} active={size === 30}>
                    30
                </Dropdown.Item>
                <Dropdown.Item onSelect={() => setSize(50)} active={size === 50}>
                    50
                </Dropdown.Item>
            </DropdownButton>
        </div>
    );
}

export default SizeDropdown;