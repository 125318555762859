import React from 'react';
import Helmet from 'react-helmet';

import SEOTypes from "./SEO.types";

function SEO({ description, lang, title }: SEOTypes) {
    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate="%s - Estate Agents in Lliria | Turia Properties"
            meta={[
                {
                    name: 'description',
                    content: description,
                },
                {
                    property: 'og:title',
                    content: title,
                },
                {
                    property: 'og:description',
                    content: description,
                },
                {
                    property: 'og:type',
                    content: 'website',
                },
                {
                    name: 'twitter:card',
                    content: 'summary',
                },
                {
                    name: 'twitter:creator',
                    content: 'Turia Properties',
                },
                {
                    name: 'twitter:title',
                    content: title,
                },
                {
                    name: 'twitter:description',
                    content: description,
                },
            ]}
        />
    );
}

SEO.defaultProps = {
    lang: 'en',
    description: "Professional family run estate agents with many years experience in real estate in Lliria. We make sure the whole buying process runs as smoothly as possible.",
};

export default SEO;
