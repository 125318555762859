import React, {useCallback, useContext, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';

import {ContactForm, ImagesPropertyGrid, PropertyDetails} from "../../components";
import { SEO } from "../../components/common";
import PropertyProps from './Property.types';
import { IProperty} from "../../domain";
import {ToasterContext} from "../../stores/toaster";
import {LoadingContext} from "../../stores/loading";

import PropertyService from "../../service/PropertyService";
import './Property.scss';

function Property(props: PropertyProps): JSX.Element {
    const { t, i18n } = useTranslation();
    const { dispatch } = useContext(ToasterContext);
    const { dispatch: loader } = useContext(LoadingContext);

    const [myProperty, setMyProperty] = useState(props?.location?.state?.property);

    const getProperty = useCallback(async (ref: string) => {
        try {
            const property: IProperty = await PropertyService.getProperty(ref);
            props.history.replace(`/property/${property.ref}/house-for-sale-${property.town.name.toLowerCase().replace(/\s/g, '-')}-${property.ref}`, { property });
            setMyProperty(property);
        } catch (error) {
            loader({show: false});
            dispatch({message: `${t("common.wrong")}`, type: "error"});
            props.history.push("/not-found");
        }
    }, [loader, dispatch, t, props.history]);

    useEffect(() => {
        loader({ show: true });
        getProperty(props.match.params.reference).then(() => loader({ show: false }));
    }, [i18n.language, props.match.params.reference, getProperty, loader]);

    return (
        <div id={"property-page"} className={"page"}>

            <SEO title={`House For Sale ${props.match.params.reference}`} description={myProperty?.description} />
            {myProperty ?
                <div>
                    <ImagesPropertyGrid images={myProperty?.images}/>
                    <Container>
                        <br />
                        <Row>
                            <Col md={12} lg={8}>
                                <PropertyDetails property={myProperty} />
                            </Col>
                            <Col md={12} lg={4}>
                                <div className={"sticky-form"}>
                                    <h3>Book this house!</h3>
                                    <hr />
                                    <ContactForm className={"contact-form"} propertyId={myProperty.ref}/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            : ''}
            <br />
        </div>
    );
}

export default Property;