import React from "react";
import { useTranslation } from "react-i18next";
import {Dropdown, DropdownButton, Image} from "react-bootstrap";

import LanguageSelectorProps from "./LanguageSelector.types";
import "./LanguageSelector.scss";

function LanguageSelector({className}: LanguageSelectorProps): JSX.Element {
    const { t , i18n} = useTranslation();

    const languages = [{
        svg: "./img/lang/es.png",
        lang: "es",
        title: t("common.lang.es")
    }, {
        svg: "./img/lang/en.png",
        lang: "en",
        title: t("common.lang.en")
    }, {
        svg: "./img/lang/de.png",
        lang: "de",
        title: t("common.lang.gr")
    }, {
        svg: "./img/lang/nl.png",
        lang: "nl",
        title: t("common.lang.nl")
    }, {
        svg: "./img/lang/fr.png",
        lang: "fr",
        title: t("common.lang.fr")
    }, {
        svg: "./img/lang/it.png",
        lang: "it",
        title: t("common.lang.it")
    }];

    const activeLanguage = i18n.language;

    function getActiveFlag() {
        const lang = languages.find(lang => activeLanguage.includes(lang.lang));
        return lang?.svg;
    }

    return (
        <div id={"language-selector"} className={className}>
            <DropdownButton id={"btn-language"} title={<Image alt={"language selected"} className={"flag"} src={getActiveFlag()}/>}>
                {languages.map((lang, i) =>(
                    <Dropdown.Item key={i} onSelect={() => i18n.changeLanguage(lang.lang)} active={activeLanguage.includes(lang.lang)}>
                        {lang.title}
                    </Dropdown.Item>

                ))}
            </DropdownButton>
        </div>
    );
}

export default LanguageSelector;