export enum SortBy  {
    PRICE = "PRICE",
    BATHROOMS = "BATHROOMS",
    BEDROOMS = "BEDROOMS",
    PLOTSIZE = "PLOTSIZE",
    BUILDSIZE = "BUILDSIZE",
    UPDATED = "UPDATED",
}

export enum OrderBy {
    ASC = "ASC",
    DESC = "DESC",
}