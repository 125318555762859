import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { FaBath, FaExpand, FaHome, IoIosBed } from "react-icons/all";

import { parseNumberWithCommas } from "../../../utils/utils";

import PropertyMainInfoProps from "./PropertyMainInfo.types";
import "./PropertyMainInfo.scss";

const PropertyMainInfo = ({ bedrooms, bathrooms, buildSize,plotSize }: PropertyMainInfoProps) => {
    const { t, i18n } = useTranslation();
    return (
        <Row>
            <Col>
                <p>
                    <label><IoIosBed/> {parseNumberWithCommas(bedrooms, i18n.language)} {t("property.beds")}</label>
                    <label className={"separator"}/>
                    <label><FaBath/> {parseNumberWithCommas(bathrooms, i18n.language)} {t("property.baths")}</label>
                    <label className={"separator"}/>
                    <label><FaHome/> {parseNumberWithCommas(buildSize, i18n.language)}m<sup>2</sup></label>
                    <label className={"separator"}/>
                    <label><FaExpand/> {parseNumberWithCommas(plotSize, i18n.language)}m<sup>2</sup></label>
                </p>
            </Col>
        </Row>
    );
};

export default PropertyMainInfo;