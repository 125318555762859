import React from "react";
import { Link } from 'react-router-dom';
import { Container, Image} from "react-bootstrap"
import { FaEnvelope, FaPhone, FaMobileAlt } from "react-icons/all";
import { useTranslation } from 'react-i18next';

import "./Footer.scss";

function Footer() {
    const { t } = useTranslation();
    return (
        <div className={"footer"}>
            <Container>
                <div className={"flex-container"}>
                    <div className={"description-block"}>
                        <div className={"title"}>
                            <Image
                                width={32}
                                height={32}
                                className="ml-3"
                                src={`${process.env.PUBLIC_URL}/favicon.ico`}
                                alt="Turia Properties Logo"
                            />
                            <h5 className={"heading-title-columns blue"}>Turia Properties</h5>
                        </div>
                        <p className={"body-text-columns"}>
                            {t("footer.description")}
                        </p>
                    </div>
                    <div className={"item"}>
                        <h5 className={"heading-title-columns blue"}>{t("footer.title_nav")}</h5>
                        <Link to={"/"} className={"footer-link"}>{t("common.home")}</Link>
                        <Link to={"/search"} className={"footer-link"}>{t("search.search")}</Link>
                        <Link to={"/about-us"} className={"footer-link"}>{t("common.about_us")}</Link>
                        <Link to={"/contact"} className={"footer-link"}>{t("common.contact_us")}</Link>
                    </div>
                    <div className={"item"}>
                        <h5 className={"heading-title-columns blue"}>{t("footer.title_contact")}</h5>
                        <p><FaEnvelope /> info@turiaproperties.com</p>
                        <p><FaPhone /> +34 962 127 662</p>
                        <p><FaMobileAlt /> +34 681 686 194</p>
                    </div>
                </div>
            </Container>
            <div className={"copyright-footer"}>
                <p>© Turia Properties</p>
            </div>
        </div>
    );
}

export default Footer;