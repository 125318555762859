import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import {Button, Container} from "react-bootstrap";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/all";

import { transformCriteriaToQueryParam } from "../../../utils/utils";
import { ICriteria } from "../../../domain";
import {OrderBy, SortBy} from "../../../domain/enums";

import BasicCriteriaProps from "./BasicCriteria.types";
import "./BasicCriteria.scss";

function BasicCriteria({ setShowReferenceCriteria, ...props }: BasicCriteriaProps) {
    const { t } = useTranslation();

    const [bedrooms, setBedrooms] = useState(0);
    const [bathrooms, setBathrooms] = useState(0);

    const goTo = (): any => {
        let criteria: ICriteria = {
            page: 1,
            size: 30,
            field: SortBy.UPDATED,
            order: OrderBy.DESC,
            sold: false,
        };
        if (bedrooms) criteria = { ...criteria, minBedrooms: bedrooms };
        if (bathrooms) criteria = { ...criteria, minBathrooms: bathrooms };

        const queryParams: string = transformCriteriaToQueryParam(criteria);
        props.history.push(`search${queryParams}`);
    };

    return (
        <div id={"search-container"} style={{backgroundImage: `url("./img/criteriaHome.jpeg")`}}>
            <Container>
                <div id={"form-container"}>`
                    <h3 className={"main-title"}>{t("common.ideal_home")}</h3>
                    <br />
                    <div className={"form-group"}>
                        <label>{t("property.bedrooms")}</label>
                        <Button className={"btn btn-bordered btn-bordered-blue btn-rounded"} disabled={bedrooms === 0} onClick={() => setBedrooms(bedrooms - 1)}><AiOutlineMinus /></Button>
                        <h5>{bedrooms} {bedrooms > 0 ? '+' : ''} </h5>
                        <Button className={"btn btn-bordered btn-bordered-blue btn-rounded"} onClick={() => setBedrooms(bedrooms + 1)}><AiOutlinePlus /></Button>
                    </div>
                    <br />
                    <div className={"form-group"}>
                        <label>{t("property.bathrooms")}</label>
                        <Button className={"btn btn-bordered btn-bordered-blue btn-rounded"} disabled={bathrooms === 0} onClick={() => setBathrooms(bathrooms - 1)}><AiOutlineMinus /></Button>
                        <h5>{bathrooms} {bathrooms > 0 ? '+' : ''}</h5>
                        <Button className={"btn btn-bordered btn-bordered-blue btn-rounded"} onClick={() => setBathrooms(bathrooms + 1)}><AiOutlinePlus /></Button>
                    </div>
                    <br />
                    <Button className={"btn btn-color btn-color-blue btn-full-size"} onClick={goTo}>
                        {t("search.search")}
                    </Button>
                    <div className={"search-by-reference"}>
                        <label className={"btn btn-ref"}>{t("search.by_ref")}</label>
                        <Button className={"btn btn-link btn-link-blue btn-ref"} onClick={() => setShowReferenceCriteria(true)}>
                            {t("common.click_here")}
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default BasicCriteria;