import React, { createContext, useReducer} from "react";

const initialState = { show: false };
const LoadingContext = createContext();

const LoadingProvider = ({ children } ) => {
    const [state, dispatch] = useReducer((state, action) => {
        if (action.show) return {...state, show: true };
        else return {...state, show: false };
    }, initialState);

    return <LoadingContext.Provider value={{ state, dispatch }}>{children}</LoadingContext.Provider>;
};

export { LoadingProvider, LoadingContext };