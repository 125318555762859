import React, {Suspense, useEffect} from "react";

import Toaster from "../../components/common/Toaster/Toaster";
import Loading from "../../components/common/Loading/Loading";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import "./Container.scss";


function Container(props: any) {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <Suspense fallback={<Loading />}>
            <Loading />
            <main id={"container"}>
                <Header location={props.location} />
                <Toaster />
                {props.children}
                <Footer />
            </main>
        </Suspense>
    );
}

export default Container;