import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Container from "./layout/Container/Container"

import {
    Home, Contact, AboutUs, PropertyDetails, Search,
} from "./pages";

function App() {
    return (
      <Router basename={process.env.PUBLIC_URL}>
              <Switch>
                  <Route exact path={"/"} render={(props) => <Container location={props.location}><Home {...props}/></Container>} />
                  <Route exact path={"/about-us"} render={(props) => <Container location={props.location}><AboutUs {...props}/></Container>} />
                  <Route exact path={"/contact"} render={(props) => <Container location={props.location}><Contact {...props}/></Container>} />
                  <Route exact path={"/search"} render={(props) => <Container location={props.location}><Search {...props}/></Container>} />
                  <Route path={"/property/:reference"} render={(props) => <Container location={props.location}><PropertyDetails {...props}/></Container>} />
                  <Route render={() => <Redirect to={"/"}/>} />
              </Switch>
      </Router>
  );
}

export default App;
