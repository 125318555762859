import React from 'react';
import { useTranslation } from 'react-i18next';

import { parseNumberWithCommas } from "../../../../utils/utils";
import {PropertyMainInfo} from "../../../common";

import MainInfoProps from './MainInfo.types'
import './MainInfo.scss';


function MainInfo(props: MainInfoProps): JSX.Element {
    const { t, i18n } = useTranslation();

    return (
        <div id={"main-info"}>
            <div className={"ref-row"}>
                <h3 className={"reference"}>REF: {props.reference}</h3>
                <h3 className={`is-sold ${props.sold ? "sold" : "sale"}`}>{t(props.sold ? "common.sold" : "common.for_sale")}</h3>
            </div>
            <div className={"price-row"}>
                <h3 className={"price"}>{parseNumberWithCommas(props.price, i18n.language)} €</h3>
            </div>
            <br/>
            <PropertyMainInfo bedrooms={props.bedrooms} bathrooms={props.bathrooms} plotSize={props.plotSize} buildSize={props.buildSize} />
        </div>
    );
}

export default MainInfo;