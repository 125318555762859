import React, {useEffect, useState} from "react";

import CheckboxProps from "./Checkbox.types";
import "./Checkbox.scss";

function Checkbox({value, onChange}: CheckboxProps): JSX.Element {
    const [checked, setChecked] = useState(value);

    useEffect(() => {
        setChecked(value);
    }, [value]);

    function clickHandler(event: any) {
        event.preventDefault();
        setChecked(!checked);
        onChange(!checked);
    }

    return (
        <button onClick={clickHandler} className={["wrapper", checked ? "active" : ""].join(" ")}>
            <div className={["indicator", checked ? "active" : ""].join(" ")} />
        </button>
    );
}

export default Checkbox;