import React from "react";
import { Link } from "react-router-dom";
import {Container, Navbar} from "react-bootstrap";
import { useTranslation } from 'react-i18next';

import "./Header.scss";
import {LanguageSelector} from "../../components/common";

function Header({location}: any) {
    const { t } = useTranslation();

    const menus = [{
        title: t("common.home"),
        to: "/",
        isActive: location?.pathname === "/"
    }, {
        title: t("search.search"),
        to: "/search",
        isActive: location?.pathname === "/search"
    }, {
        title: t("common.about_us"),
        to: "/about-us",
        isActive: location?.pathname === "/about-us"
    }, {
        title: t("common.contact_us"),
        to: "/contact",
        isActive: location?.pathname === "/contact"
    }]

    return (
        <Navbar expand={"md"} sticky={"top"} className={"navbar-color"}>
            <Container>
                <Navbar.Brand>
                    <Link to={"/"}>
                        <img
                            src={"./img/logos/Color.png"}
                            className="brand-image d-inline-block align-top"
                            alt="Turia Properties logo"
                        />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav">
                    <span />
                    <span />
                    <span />
                </Navbar.Toggle>
                <div className={"language-mobile"}>
                    <LanguageSelector/>
                </div>
                <Navbar.Collapse id={"collapsible-menu"}>
                    {menus.map((menu, i) => (
                        <Link key={i} className={`nav-link ${menu.isActive ? "active" : ""}`} to={menu.to}>{menu.title}</Link>
                    ))}
                </Navbar.Collapse>
                <div className={"language-desktop"}>
                    <LanguageSelector/>
                </div>
            </Container>
        </Navbar>
    );
}

export default Header;