import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";

import {Image, PropertyMainInfo} from "../../common/index";
import { parseNumberWithCommas } from "../../../utils/utils";

import PropertyCardProps from "./PropertyCardProps.types";
import "./PropertyCard.scss";

const PropertyCard = ({ property, showDescription, ...props }: PropertyCardProps) => {
    const { i18n } = useTranslation();
    return (
        <Card key={property.id} onClick={ () => props.history.push(`property/${property.ref}`, { state: { property } }) }>
            <div className={"container-img"}>
                <Image
                    src={property.images[0]}
                    placeholder={"favicon.ico"}
                    fallback={"favicon.ico"}
                    alt={"image"}
                    className={"card-img-top"}
                />
                {property.sold ?
                    <label className={"top-label sold"}>SOLD</label>
                : ''}
                <label className={"bottom-label reference"}>REF:{property.ref}</label>
            </div>
            <Card.Body>
                <PropertyMainInfo bedrooms={property.bedrooms} bathrooms={property.bathrooms} plotSize={property.plotSize} buildSize={property.buildSize} />
                {showDescription ? <Card.Text className={"description"}>{property.description}</Card.Text> : ''}
                <Card.Title className={"float-right price"}>{parseNumberWithCommas(property.price, i18n.language)} €</Card.Title>
            </Card.Body>
        </Card>
    );
};

export default PropertyCard;