import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import { useTranslation } from 'react-i18next';
import {FaExclamationTriangle} from "react-icons/all";
import {Button, Col, Form, Modal} from "react-bootstrap";

import { IProperty} from "../../../domain";
import PropertyService from "../../../service/PropertyService";
import {ToasterContext} from "../../../stores/toaster";
import {LoadingContext} from "../../../stores/loading";

import ByReferenceCriteriaProps from "./ReferenceCriteria.types";
import "./ReferenceCriteria.scss";

function ReferenceCriteria({showReferenceCriteria, setShowReferenceCriteria, ...props}: ByReferenceCriteriaProps) {
    const { t } = useTranslation();
    const { dispatch } = useContext(ToasterContext);
    const { dispatch: loader } = useContext(LoadingContext);

    const handleClose = useCallback(() => setShowReferenceCriteria(false), [setShowReferenceCriteria]);
    const [reference, setReference] = useState<string>('');
    const [showNotFoundMessage, setShowNotFoundMessage] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    });

    async function searchByReference(e: any) {
        e.preventDefault();
        loader({show: true});
        try {
            const property: IProperty | undefined = await PropertyService.getProperty(reference);
            console.log(property);
            loader({show: false});
            if (property) props.history.push(`property/${property.ref}`, { state: { property } });
            else {
                loader({show: false});
                setReference('');
                setShowNotFoundMessage(true);
            }
        } catch (error) {
            loader({show: false});
            setReference('');
            setShowNotFoundMessage(true);
            dispatch({message: `${t("common.wrong")}: ${error.message}`, type: "error"});
        }
    }
    return (
        <Modal id={"modal-by-ref"} show={showReferenceCriteria} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t("search.search_ref")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={searchByReference}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="reference">
                            <Form.Label>{t("search.type_ref")}:</Form.Label>
                            <Form.Control
                                required
                                ref={inputRef}
                                type="text"
                                placeholder={t("search.ref_example")}
                                value={reference}
                                onChange={e => setReference(e.currentTarget.value.trim())}
                            />
                        </Form.Group>
                    </Form.Row>
                </Form>
                {showNotFoundMessage ?
                    <div>
                        <p className={"not-found"}><FaExclamationTriangle/> Not found property with this reference. Try it again.</p>
                    </div>
                : ''}
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn btn-color btn-color-blue" disabled={!reference} onClick={searchByReference}>
                  {t('search.search')}
              </Button>
            </Modal.Footer>
          </Modal>
    );
}

export default ReferenceCriteria;