import React from 'react';

import Description from "./Description/Description";
import MainInfo from "./MainInfo/MainInfo";
import Amenities from "./Amenities/Amenities";
import {IAmenities} from "../../../domain";

import PropertyDetailsProps from './PropertyDetails.types'
import './PropertyDetails.scss';

function PropertyDetails({ property }: PropertyDetailsProps): JSX.Element {

    const amenities: IAmenities = {
        logBurner: property.logBurner,
        gas: property.gas,
        water: property.water,
        electricity: property.electricity,
        sewage: property.sewage,
        internet: property.internet,
        pool: property.pool,
        busRoute: property.busRoute,
        schoolRoute: property.schoolRoute,
        garage: property.garage,
        airConditioning: property.airConditioning,
    };

    return (
        <div>
            <MainInfo
                reference={property.ref}
                price={property.price}
                sold={property.sold}
                bedrooms={property.bedrooms}
                bathrooms={property.bathrooms}
                plotSize={property.plotSize}
                buildSize={property.buildSize}
            />
            <br/>
            <Description description={property.description}/>
            <br />
            <Amenities amenities={amenities} />
        </div>
    );
}

export default PropertyDetails;