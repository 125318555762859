import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/all";
import {Button} from "react-bootstrap";

import PropertyCard from "../Card/PropertyCard";
import { IProperty } from "../../../domain";

import PropertyCarouselProps from "./PropertyCarouselProps.types";
import "./PropertyCarousel.scss";

function PropertyCarousel({ properties, ...props }: PropertyCarouselProps) {
    const responsiveCarousel = {
        desktop: {
            breakpoint: {
                max: 3000,
                min: 1024
            },
            items: 3,
            partialVisibilityGutter: 40
        },
        mobile: {
            breakpoint: {
                max: 464,
                min: 0
            },
            items: 1,
            partialVisibilityGutter: 30
        },
        tablet: {
            breakpoint: {
                max: 1024,
                min: 464
            },
            items: 2,
            partialVisibilityGutter: 30
        }
    };

    const ArrowButtons = ({ previous, next }: any): JSX.Element  => {
        return (
            <div className={"arrow-buttons"}>
                <Button className={"btn btn-link btn-link-blue btn-arrows float-left"} onClick={() => previous()}><IoIosArrowBack/></Button>
                <Button className={"btn btn-link btn-link-blue btn-arrows float-right"} onClick={() => next()}><IoIosArrowForward/></Button>
            </div>
        );
    };

    return (
        <div id={"property-carousel"}>
            <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlay={true}
                customButtonGroup={<ArrowButtons />}
                autoPlaySpeed={4000}
                centerMode={false}
                draggable={true}
                focusOnSelect={false}
                infinite={true}
                keyBoardControl={true}
                minimumTouchDrag={80}
                responsive={responsiveCarousel}
                showDots={false}
                slidesToSlide={1}
                swipeable={true}
            >
                {properties.map((property: IProperty, i: number) => (<PropertyCard key={i} property={property} showDescription={true} {...props}/>))}
            </Carousel>
        </div>
    );
}

export default PropertyCarousel;
