import React from "react";
import { useTranslation } from 'react-i18next';
import { FaRegEnvelope, MdPhoneInTalk } from "react-icons/all";
import { Row, Col } from "react-bootstrap";

import { ContactForm, SEO } from "../../components";


import ContactTypes from "./Contact.types";
import "./Contact.scss";

function Contact(props: ContactTypes) {
    const { t } = useTranslation();

    return (
        <div id={"contact-page"} className={"page"}>
            <SEO title={"Contact Us"} />
            <div className={"contact-container"} style={{backgroundImage: `url("./img/maps.png")`}}>
                <Row className={"form-container"}>
                    <Col sm={12} md={4} className={"phone-container"}>
                            <h3>@</h3>
                            <p>info@turiaproperties.com</p>
                            <h3><MdPhoneInTalk /></h3>
                            <p>
                                +34 962 127 662<br />
                                +34 681 686 194<br/>
                            </p>
                    </Col>
                    <Col sm={0} md={1} className={"separation"}/>
                    <Col sm={12} md={7}>
                        <h3><FaRegEnvelope/></h3>
                        <h3>{t("contact.get_in_touch")}</h3>
                        <br/>
                        <ContactForm />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default Contact;