import React, {useEffect, useState} from "react";

import {transformCriteriaToQueryParam} from "../../../utils/utils";
import {OrderBy, SortBy} from "../../../domain/enums";
import {ICriteria} from "../../../domain";
import SizeDropdown from "./SizeDropdown/SizeDropdown";
import SortByDropdown from "./SortByDropdown/SortByDropdown";

import HeaderSaleProps from "./HeaderSale.types";
import "./HeaderSale.scss";

function HeaderSale({criteria, ...props}: HeaderSaleProps): JSX.Element {
    const [criteriaState, setCriteriaState] = useState<ICriteria>(criteria);

    useEffect(() => {
        props.history.push(`search${transformCriteriaToQueryParam(criteriaState)}`);
    }, [criteriaState, props.history]);

    function setSize(size: number): void {
        setCriteriaState({ ...criteriaState, size, page: 1});
    }

    function setOrderBy(field: SortBy, order: OrderBy): void {
        setCriteriaState({ ...criteriaState, field, order, page: 1});
    }

    return (
        <div id={"header-sale"}>
            <SortByDropdown field={criteriaState.field} order={criteriaState.order} setOrderBy={setOrderBy} />
            <SizeDropdown size={criteriaState.size} setSize={setSize}/>
        </div>
    );
}

export default HeaderSale;