import Axios from "axios";
import i18n from "i18next";
import { IProperty, IPage } from "../domain";

const BASE_URL: string = `${process.env.REACT_APP_CMS_URL}`;
const endpoints = {
    getProperty: (id: string) => `${BASE_URL}/property/${id}`,
    getPropertiesByFilters: (filters: string) => `${BASE_URL}/property${filters}`,
};

const getHeaders = (): any => ( { "Accept-Language": i18n.language.toUpperCase() } );

const getProperty = (propertyId: string): Promise<IProperty> => {
    return Axios.get(endpoints.getProperty(propertyId), {headers: getHeaders()})
        .then(res => res.data)
        .catch(err => { throw err; });
};

const getPropertyByReference = (reference: string): Promise<IProperty | undefined> => {
  return Axios.get(endpoints.getPropertiesByFilters(`?ref=${reference}`), {headers: getHeaders()})
    .then(res => res.data.properties[0])
    .catch(err => { throw err; });
};

const getProperties = (filters: string): Promise<IPage> => {
  return Axios.get(endpoints.getPropertiesByFilters(filters), {headers: getHeaders()})
    .then(res => res.data)
    .catch(err => { throw err; });
};

export default { getProperty, getPropertyByReference, getProperties };
