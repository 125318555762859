import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Tooltip from "rc-tooltip";
import { Range, Handle} from "rc-slider";
import 'rc-slider/assets/index.css';

import {parseNumberWithCommas} from "../../../../utils/utils";

import PriceLabelProps from "./PriceLabel.types";
import "./PriceLabel.scss";

function PriceLabel({ minPrice, maxPrice, setPrice, className }: PriceLabelProps): JSX.Element {
    const { t, i18n } = useTranslation();

    const [pricePair, setPricePair] = useState<number[]>([minPrice || 0, maxPrice || 600000]);

    let typingTimer: NodeJS.Timeout;
    function handlerOnKeyUp(value: number[]): void {
        setPricePair(value);
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => setPrice(value), 2000);
    }

    function handlerOnKeyDown(): void {
        clearTimeout(typingTimer);
    }

    const handleTooltip = (props: any) => {
        const { value, dragging, index, ...restProps } = props;
        return (
            <Tooltip
                prefixCls="rc-slider-tooltip"
                overlay={`${parseNumberWithCommas(value, i18n.language)} €`}
                visible={dragging}
                placement="top"
                key={index}
            >
                <Handle value={`${parseNumberWithCommas(value, i18n.language)} €`} {...restProps} />
            </Tooltip>
        );
    };

    return (
        <div id={"price-label"} className={className}>
            <h4>{`${t("property.price")} (€)`}</h4>
            <div className={"row price-row"}>
                <div className={"col"}>
                    <div className={"form-group"}>
                        <Range
                            value={pricePair}
                            min={0}
                            max={600000}
                            onChange={(pair: number[]) => setPricePair(pair)}
                            onAfterChange={setPrice}
                            handle={handleTooltip}
                            step={10000}
                        />
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <label>{t("search.min")}</label>
                    <input
                        type={"number"}
                        className={"form-control"}
                        value={pricePair[0] || 0}
                        min={0}
                        max={pricePair[1] || 600000}
                        onChange={e => handlerOnKeyUp([parseInt(e.currentTarget.value), pricePair[1]])}
                        onKeyDown={handlerOnKeyDown}
                    />
                </div>
                <div className={"col"}>
                    <label>{t("search.max")}</label>
                    <input
                        type={"number"}
                        className={"form-control"}
                        value={pricePair[1] || 0}
                        min={pricePair[0] || 0}
                        max={600000}
                        onChange={e => handlerOnKeyUp([pricePair[0], parseInt(e.currentTarget.value)])}
                        onKeyDown={handlerOnKeyDown}
                    />
                </div>
            </div>
        </div>
    );
}

export default PriceLabel;