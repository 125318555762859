import { ICriteria } from "../domain";
import { SortBy, OrderBy } from "../domain/enums";

function transformStringToSortBy(value: string): SortBy | undefined {
    let result: SortBy | undefined;
    switch (value.toUpperCase()) {
        case "PRICE": result = SortBy.PRICE;
            break;
        case "BATHROOMS": result = SortBy.BATHROOMS;
            break;
        case "BEDROOMS": result = SortBy.BEDROOMS;
            break;
        case "PLOTSIZE": result = SortBy.PLOTSIZE;
            break;
        case "BUILDSIZE": result = SortBy.BUILDSIZE;
            break;
        case "UPDATED": result = SortBy.UPDATED;
            break;
        default: result = undefined;
            break;
    }
    return result;
}

function transformStringToOrderBy(value: string): OrderBy | undefined {
    let result: OrderBy | undefined;
    switch (value.toUpperCase()) {
        case "ASC": result = OrderBy.ASC;
            break;
        case "DESC": result = OrderBy.DESC;
            break;
        default: result = undefined;
            break;
    }
    return result;
}

export function parseNumberWithCommas(number: number, language: string): string {
    let num: string = number.toString();
    const pattern = /(-?\d+)(\d{3})/;
    const thousandsSeparator = language.includes("es-ES") ? "." : ",";
    while (pattern.test(num))
        num = num.replace(pattern, `$1${thousandsSeparator}$2`);
    return num;
}

export function transformCriteriaToQueryParam(criteria: ICriteria): string {
    return `?${
        Object.entries(criteria)
            .filter(([_, value]) => value !== undefined)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")
    }`;
}

export function transformQueryParamToCriteria(queryParam: string): ICriteria {
    const pairValues: string[][] = queryParam.slice(1).split("&").map(value => value.split("="));

    let criteria: ICriteria = {};
    pairValues.forEach(pair => {
        if (pair[0] === "maxBathrooms" ||
            pair[0] === "maxBedrooms" ||
            pair[0] === "maxPrice" ||
            pair[0] === "minBathrooms" ||
            pair[0] ===  "minBedrooms" ||
            pair[0] ===  "minPrice" ||
            pair[0] === "page" ||
            pair[0] === "size") {
            criteria = criteria = { ...criteria, [`${pair[0]}`]: parseInt(pair[1]) };
        }
        else if (pair[0] === "sold") criteria = criteria = { ...criteria, [`${pair[0]}`]: pair[1] === "true" };
        else if (pair[0] === "field") criteria = criteria = { ...criteria, [`${pair[0]}`]: transformStringToSortBy(pair[1]) };
        else if (pair[0] === "order") criteria = criteria = { ...criteria, [`${pair[0]}`]: transformStringToOrderBy(pair[1]) };
    });

    return criteria;
}