import React, {useEffect, useState} from 'react';
import ProgressiveImage from 'react-progressive-image';

import ProgressiveImageTypes from "./ProgressiveImage.types";

const Image = React.memo(function Image(props: ProgressiveImageTypes) {
    const [srcSet, setSrcSet] = useState({ src: props.src, placeholder: props.placeholder });

    useEffect(() => {
        setSrcSet({ src: props.src, placeholder: props.placeholder });
    }, [props]);

    function handleFallback() {
        setSrcSet(props.fallback);
    }

    return (
        <ProgressiveImage {...srcSet} delay={300}>
            {(src: string, loading: boolean) => (
                <img style={{ opacity: loading ? 0.2 : 1 }} src={src} alt={props.alt} className={props.className} onError={handleFallback} />
            )}
        </ProgressiveImage>
    );
});

export default Image;