import React from 'react';
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import {
    IoIosBonfire,IoIosWater,  MdSchool, GiHomeGarage, GiWaterfall,
    FaWifi, FaSwimmingPool, FaBusAlt, FaRegSnowflake, FaBurn, FaLightbulb,
} from "react-icons/all";

import AmenitiesProps from './Amenities.types'
import './Amenities.scss';

function Amenities({ amenities }: AmenitiesProps): JSX.Element {
    const { t } = useTranslation();

    const initializeAmenityList = (): JSX.Element[] => {
        let aux: JSX.Element[] = [];
        if (amenities.electricity) aux.push(<p key={"amenities.electricity"}><FaLightbulb /> {t("amenities.electricity")}</p>);
        if (amenities.water) aux.push(<p key={"amenities.water"}><IoIosWater /> {t("amenities.water")}</p>);
        if (amenities.gas) aux.push(<p key={"amenities.gas"}><FaBurn /> {t("amenities.gas")}</p>);
        if (amenities.internet) aux.push(<p key={"amenities.internet"}><FaWifi /> {t("amenities.internet")}</p>);
        if (amenities.garage) aux.push(<p key={"amenities.garage"}><GiHomeGarage /> {t("amenities.garage")}</p>);
        if (amenities.airConditioning) aux.push(<p key={"amenities.aircon"}><FaRegSnowflake /> {t("amenities.aircon")}</p>);
        if (amenities.logBurner) aux.push(<p key={"amenities.log_burner"}><IoIosBonfire /> {t("amenities.log_burner")}</p>);
        if (amenities.pool) aux.push(<p key={"amenities.pool"}><FaSwimmingPool /> {t("amenities.pool")}</p>);
        if (amenities.busRoute) aux.push(<p key={"amenities.bus"}><FaBusAlt /> {t("amenities.bus")}</p>);
        if (amenities.schoolRoute) aux.push(<p key={"amenities.school"}><MdSchool /> {t("amenities.school")}</p>);
        if (amenities.sewage) aux.push(<p key={"amenities.sewage"}><GiWaterfall /> {t("amenities.sewage")}</p>);
        return aux;
    };
    const amenityList: JSX.Element[] = initializeAmenityList();

    const columnOne: JSX.Element[] = (amenityList.length > 5) ? amenityList.slice(0, Math.round(amenityList.length / 2) - 1) : amenityList;
    const columnTwo: JSX.Element[] = (amenityList.length > 5) ? amenityList.slice(Math.round(amenityList.length / 2), amenityList.length) : [];

    return (
        <div id={"amenities"}>
            <h4>Amenities</h4>
            <hr />
            <Row>
                <Col md={12} lg={6}>
                    {columnOne.map(el => el)}
                </Col>
                <Col md={12} lg={6}>
                    {columnTwo.map(el => el)}
                </Col>
            </Row>
        </div>
    );
}

export default Amenities;