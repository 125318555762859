import React from "react";

import {PropertyCard} from "../index";
import {Col, Row} from "react-bootstrap";
import {IProperty} from "../../../domain";

import PropertyListProps from "./PropertyList.types";
import "./PropertyList.scss";

function PropertyList({ properties, ...props} : PropertyListProps): JSX.Element {

    return (
        <div id={"property-list"}>
            <Row>
                {properties.map((property: IProperty, i: number) => (
                    <Col key={i} sm={12} md={6} lg={4}>
                        <PropertyCard property={property} showDescription={true} {...props}/>
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default PropertyList;