import React from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { FaUsers, FaFileContract, FaHandsHelping,  FaHandHoldingHeart, FaRegComments } from "react-icons/all";
import { Container, Row, Col } from "react-bootstrap";

import { SEO } from "../../components";

import AboutUsTypes from "./AboutUs.types";
import "./AboutUs.scss";

function AboutUs(props: AboutUsTypes) {
    const { t } = useTranslation();

    return (
        <div id="about-us-page" className={"page"}>
            <SEO title={"About Us"} />
            <Container>
                <h1 className={"header"}>{t("about-us.header")}</h1>
                <hr />
                <Row>
                    <Col xs={12} sm={6} md={3}>
                        <h2 className={"icon"}><FaUsers/></h2>
                        <p><Trans i18nKey={"about-us.paragraph1"}/></p>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <h2 className={"icon"}><FaFileContract/></h2>
                        <p><Trans i18nKey={"about-us.paragraph2"}/></p>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <h2 className={"icon"}><FaHandsHelping/></h2>
                        <p><Trans i18nKey={"about-us.paragraph3"}/></p>
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        <h2 className={"icon"}><FaHandHoldingHeart/></h2>
                        <p><Trans i18nKey={"about-us.paragraph4"}/></p>
                    </Col>
                </Row>
                <br />
                <div className={"contact-container"} style={{backgroundImage: `url("./img/sale.jpg")`}}>
                    <div className={"contact-button"}>
                        <Link to="/contact" className={"btn btn-color btn-color-blue"}><FaRegComments className={"icon-btn"}/> {t("contact.message")}</Link>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default AboutUs;