import React, {useCallback, useContext, useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {FaRegEnvelope} from "react-icons/all";
import {Container} from "react-bootstrap";

import {BasicCriteria, ByReferenceCriteria, ContactForm, PropertyCarousel, SEO} from "../../components";
import {IPage, IProperty} from "../../domain";
import PropertyService from "../../service/PropertyService";
import {transformCriteriaToQueryParam} from "../../utils/utils";
import {ToasterContext} from "../../stores/toaster";
import {LoadingContext} from "../../stores/loading";
import ICriteria from "../../domain/Criteria";
import {OrderBy, SortBy} from "../../domain/enums";

import HomeProps from './Home.types';
import "./Home.scss";

function Home(props: HomeProps) {
    const { t } = useTranslation();
    const { dispatch } = useContext(ToasterContext);
    const { dispatch: loader } = useContext(LoadingContext);

    const [properties, setProperties] = useState<IProperty[]>([]);
    const [showReferenceCriteria, setShowReferenceCriteria] = useState(false);

    const getLastUpdatedProperties = useCallback(async () => {
        const criteria: ICriteria = {
            page: 1,
            size: 6,
            field: SortBy.UPDATED,
            order: OrderBy.DESC,
        };
        try {
            const page: IPage = await PropertyService.getProperties(transformCriteriaToQueryParam(criteria));
            setProperties(page.properties);
        } catch (error) {
            loader({show: false});
            dispatch({message: `${t("common.wrong")}`, type: "error"});
        }
    }, [loader, dispatch, t]);

    useEffect(() => {
        loader({ show: true });
        getLastUpdatedProperties().then(() => loader({ show: false }));
    }, [getLastUpdatedProperties, loader]);

    return (
        <div id={"home-page"} className={"page"}>

            <SEO title={"Find Your Ideal Home"} />
            <ByReferenceCriteria showReferenceCriteria={showReferenceCriteria} setShowReferenceCriteria={setShowReferenceCriteria} {...props}/>
            <BasicCriteria setShowReferenceCriteria={setShowReferenceCriteria} {...props}/>
            <br />
            <Container className={"home-container"}>
                <h1>{t("home.featured_properties")}</h1>
                <hr />
                <PropertyCarousel properties={properties} {...props}/>
            </Container>
            <br />
            <div id={"contact-container"} style={{backgroundImage: `url("./img/footer.jpg")`}}>
                <Container>
                    <div id={"form-container"}>
                        <h3 className={"main-text"}><FaRegEnvelope/></h3>
                        <br/>
                        <ContactForm/>
                    </div>
                </Container>
            </div>
        </div>
    );
}

export default Home;
