import React, { useContext } from "react";

import {LoadingContext} from "../../../stores/loading";

import "./Loading.scss";

function Loading(): JSX.Element {

    const { state } = useContext(LoadingContext);

    return (
        state.show ?
            <div id={"loader"}>
                <div className={"loading-container"}>
                    <div className={"leftLine"}/>
                    <div className={"leftUpLine"}/>
                    <div className={"leftAngleLine"}/>
                    <div className={"rightAngleLine"}/>
                    <div className={"rightUpLine"}/>
                    <div className={"rightLine"}/>
                </div>
            </div>
        : <div />
    );
}

export default Loading;