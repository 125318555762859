import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {AiOutlineMinus, AiOutlinePlus} from "react-icons/all";
import {Button} from "react-bootstrap";

import {ICriteria} from "../../../domain";
import {transformCriteriaToQueryParam} from "../../../utils/utils";

import AdvancedCriteriaProps from "./AdvancedCriteria.types";
import "./AdvancedCriteria.scss";
import PriceLabel from "./PriceLabel/PriceLabel";
import {Checkbox} from "../../common";

function AdvancedCriteria({ criteria, className, ...props}: AdvancedCriteriaProps): JSX.Element {
    const { t } = useTranslation();

    const [criteriaState, setCriteriaState] = useState<ICriteria>(criteria);

    const setPropertyCriteria = (key: keyof ICriteria, value: ICriteria[keyof ICriteria]): void => {
        if (typeof value === "number" && value <= 0) setCriteriaState({ ...criteriaState, [`${key}`]: undefined, page: 1 });
        else setCriteriaState({ ...criteriaState, [`${key}`]: value, page: 1 });
    };

    function setPrice(pricePair: number[]): void {
        setCriteriaState({ ...criteriaState, minPrice: pricePair[0], maxPrice: pricePair[1], page: 1 });
    }

    useEffect(() => {
        props.history.push(`search${transformCriteriaToQueryParam(criteriaState)}`);
    }, [criteriaState, props.history]);

    const renderForm = (property: number, key: keyof ICriteria, title: string) : JSX.Element => (
        <div className={"col"}>
            <label>{title}</label>
            <div className={"form-group"}>
                <Button className={"btn btn-bordered btn-bordered-blue btn-rounded"} disabled={property === 0} onClick={() => setPropertyCriteria(key, property - 1)}><AiOutlineMinus /></Button>
                <h5>{property}</h5>
                <Button className={"btn btn-bordered btn-bordered-blue btn-rounded"} onClick={() => setPropertyCriteria(key, property + 1)}><AiOutlinePlus /></Button>
            </div>
        </div>
    );

    const showSoldProperties = (): boolean => {
        if (criteriaState.sold === true) return true;
        else return criteriaState.sold !== false;
    }

    return (
        <div id={"advanced-criteria"} className={className}>
            <div className={"sold-filter"}>
                    <label>{t("search.only_for_sale")}</label>
                    <Checkbox value={showSoldProperties()} onChange={value => setPropertyCriteria("sold", (value ? undefined : false))}/>
            </div>
            <hr />
            <div className={"b-filters"}>
                <div className={"bedroom-filter"}>
                    <h4>{t("property.bedrooms")}</h4>
                    <div className={"row"}>
                        {renderForm(criteria?.minBedrooms || 0, "minBedrooms", t("search.minimum"))}
                        {renderForm(criteria?.maxBedrooms || 0, "maxBedrooms", t("search.maximum"))}
                    </div>
                </div>
                <hr/>
                <div className={"bathroom-filter"}>
                    <h4>{t("property.bathrooms")}</h4>
                    <div className={"row"}>
                        {renderForm(criteria?.minBathrooms || 0, "minBathrooms", t("search.minimum"))}
                        {renderForm(criteria?.maxBathrooms || 0, "maxBathrooms", t("search.maximum"))}
                    </div>
                </div>
            </div>
            <hr/>
            <PriceLabel minPrice={criteriaState?.minPrice} maxPrice={criteriaState?.maxPrice} setPrice={setPrice} className={"price-filter"}/>
        </div>
    );
}

export default AdvancedCriteria;