import React from "react";
import { useTranslation } from "react-i18next";


import {PropertyList} from "../../Property";
import {FooterSale, HeaderSale} from "../index";

import ResultSaleProps from "./ResultSale.types";
import "./ResultSale.scss";

function ResultSale({ page, criteria, className, ...props}: ResultSaleProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <div id={"result-sale"} className={className}>
            <HeaderSale criteria={criteria} {...props}/>
            <br />
            <PropertyList properties={page?.properties || []} {...props}/>
            <p className={"found-elements"}>{page?.totalProperties || 0} {t("common.found")}</p>
            <br />
            <FooterSale page={page} criteria={criteria} {...props}/>
        </div>
    );
}

export default ResultSale;