import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        ns: ['translation'],
        fallbackLng: {
            'es-ES': ['es'],
            default: ['en'],
        },
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ['navigator'],
        },
        backend: {
            loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`
        }
    })
    .then(function(t) { t('es'); });


export default i18n;