import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

import ModalImagesProperty from "../Modal/ModalImagesProperty";

import ImagesPropertyGridProps from "./ImagesPropertyGrid.types"
import "./ImagesPropertyGrid.scss";

function ImagesPropertyGrid({ images }: ImagesPropertyGridProps): JSX.Element {
    const { t } = useTranslation();
    let countImages:number = 3;
    let imagesToShow: string[] = images.slice(0, countImages);
    let primaryIdx = 0;
    if (images.length < countImages) {
        primaryIdx = -1;
        countImages = images.length;
        imagesToShow = images;
    }
    if (countImages > 2 && countImages % 2 !== 0) {
        primaryIdx = Math.floor(countImages / 2);
        for (let i = 0; i < primaryIdx; i++) {
            const aux = imagesToShow[i];
            imagesToShow[i] = imagesToShow[i + 1];
            imagesToShow[i + 1] = aux;
        }
    }

    const [showModalImages, setShowModalImages] = useState<boolean>(false);
    const handleShowImages = (): void => {
        setShowModalImages(true);
    };
    const handleCloseImages = (): void => {
        setShowModalImages(false);
    };

    const renderImage = (src: string, className?: string): JSX.Element => (
        <div className={`${className}box`} key={src}>
            <div className={"image"} style={{ backgroundImage: `url(${src})`}} />
        </div>
    );

    let multipleImages = (): JSX.Element[] => {
        return (
            imagesToShow.map((image, i) => (
                renderImage(image, (i === primaryIdx) ? "main-" : '')
            ))
        );
    };

    return (
        <div id={"images-property-grid"}>
            <ModalImagesProperty show={showModalImages} handleClose={handleCloseImages} images={images}/>
            <div className={"images-row"} onClick={handleShowImages}>
                {multipleImages()}
                <Button className={"btn btn-bordered btn-bordered-grey btn-open-modal"} onClick={handleShowImages}>
                    {t("property.view_images")}
                </Button>
            </div>
        </div>
    );
}

export default ImagesPropertyGrid;