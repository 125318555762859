import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {FaChevronLeft, FaChevronRight} from "react-icons/all";

import {ICriteria} from "../../../domain";

import FooterSaleProps from "./FooterSale.types";
import "./FooterSale.scss";

import {transformCriteriaToQueryParam} from "../../../utils/utils";

function FooterSale({page, criteria, ...props}: FooterSaleProps): JSX.Element {
    const [criteriaState, setCriteriaState] = useState<ICriteria>(criteria);

    useEffect(() => {
        props.history.push(`search${transformCriteriaToQueryParam(criteriaState)}`);
    }, [criteriaState, props.history]);

    function setPage(p: number): void {
        if (p > page.totalPages) p = page.totalPages;
        if (p <= 0) p = 1;

        setCriteriaState({...criteriaState, page: p});
    }

    function buttonsToShow(): number[] {
        if (page.totalPages > 5) {
            if (page.currentPage < 5) return [1, 2, 3, 4, 5, 0, page.totalPages];
            else if (page.currentPage > page.totalPages - 4) return [1, 0, page.totalPages - 4, page.totalPages - 3, page.totalPages - 2, page.totalPages - 1, page.totalPages];
            else return [1, 0, page.currentPage - 2, page.currentPage - 1, page.currentPage, page.currentPage + 1, page.currentPage + 2, 0, page.totalPages];
        } else {
            const pages: number[] = [];
            for (let i:number = 1; i <= page.totalPages; i++) {
                pages.push(i);
            }
            return pages;
        }

    }

    function renderPagination() {
        const pages: number[] = buttonsToShow();
        return (
            pages.map((p, i) => {
                if (p === 0) return <p key={i}>...</p>;
                else return <Button
                    key={i}
                    className={`btn btn-rounded ${page.currentPage === p ? " btn-color btn-color-blue" : "btn-bordered btn-bordered-black" }`}
                    onClick={() => setPage(p)}
                >
                    {p}
                </Button>;
            })
        );
    }

    return (
        <div id={"footer-sale"}>
            <div id={"pagination"}>
                <Button className={"btn btn-link btn-link blue btn-arrows"} onClick={() => setPage(page.currentPage - 1)}><FaChevronLeft /></Button>
                {renderPagination()}
                <Button className={"btn btn-link btn-link blue btn-arrows"} onClick={() => setPage(page.currentPage + 1)}><FaChevronRight  /></Button>
            </div>
        </div>
    );
}

export default FooterSale;