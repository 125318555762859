import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Dropdown, DropdownButton} from "react-bootstrap";

import {OrderBy, SortBy} from "../../../../domain/enums";

import SortByProps from "./SortByDropdown.types";
import "./SortByDropdown.scss";

function SortByDropdown({field, order, setOrderBy}: SortByProps): JSX.Element {
    const { t } = useTranslation();

    const sortByElements: any  = {
        "new": {
            field: SortBy.UPDATED,
            order: OrderBy.DESC
        },
        "min_price": {
            field: SortBy.PRICE,
            order: OrderBy.ASC
        },
        "max_m2": {
            field: SortBy.BUILDSIZE,
            order: OrderBy.DESC
        },
        "max_price": {
            field: SortBy.PRICE,
            order: OrderBy.DESC
        },
        "min_beds": {
            field: SortBy.BEDROOMS,
            order: OrderBy.ASC
        },
        "max_beds": {
            field: SortBy.BEDROOMS,
            order: OrderBy.DESC
        },
        "min_baths": {
            field: SortBy.BATHROOMS,
            order: OrderBy.ASC
        },
        "max_baths": {
            field: SortBy.BATHROOMS,
            order: OrderBy.DESC
        },
        "min_m2": {
            field: SortBy.BUILDSIZE,
            order: OrderBy.ASC
        },
        "min_build": {
            field: SortBy.PLOTSIZE,
            order: OrderBy.ASC
        },
        "max_build": {
            field: SortBy.PLOTSIZE,
            order: OrderBy.DESC
        },
        "oldest": {
            field: SortBy.UPDATED,
            order: OrderBy.ASC
        }
    };

    function isActive(key: string) {
        const element = sortByElements[key];
        return field && order && field === element?.field && order === element?.order;
    }

    function setOrderValues({ field, order }: {field: SortBy, order: OrderBy}) {
        setOrderBy(field, order);
    }

    return (
        <div id={"sort-by-component"}>
            <Button onClick={() => setOrderValues(sortByElements["new"])} className={`btn-filter ${isActive("new") ? "active" : ""}`}>
                {t("filters.new")}
            </Button>
            <Button onClick={() => setOrderValues(sortByElements["min_price"])} className={`btn-filter ${isActive("min_price") ? "active" : ""}`}>
                {t("filters.min_price")}
            </Button>
            <Button onClick={() => setOrderValues(sortByElements["max_m2"])} className={`btn-filter ${isActive("max_m2") ? "active" : ""}`}>
                {t("filters.max")} m<sup>2</sup>
            </Button>
            <DropdownButton id={"sort-by"} title={t("filters.sort")}>
                <Dropdown.Item active={isActive("new")} onSelect={() => setOrderValues(sortByElements["new"])}>
                    {t("filters.new")}
                </Dropdown.Item>
                <Dropdown.Item active={isActive("min_price")} onSelect={() => setOrderValues(sortByElements["min_price"])}>
                    {t("filters.min_price")}
                </Dropdown.Item>
                <Dropdown.Item active={isActive("max_m2")} onSelect={() => setOrderValues(sortByElements["max_m2"])}>
                    {t("filters.max")} m<sup>2</sup>
                </Dropdown.Item>
                <Dropdown.Item active={isActive("max_price")} onSelect={() => setOrderValues(sortByElements["max_price"])}>
                    {t("filters.max_price")}
                </Dropdown.Item>
                <Dropdown.Item active={isActive("min_beds")} onSelect={() => setOrderValues(sortByElements["min_beds"])}>
                    {t("filters.min_beds")}
                </Dropdown.Item>
                <Dropdown.Item active={isActive("max_beds")} onSelect={() => setOrderValues(sortByElements["max_beds"])}>
                    {t("filters.max_beds")}
                </Dropdown.Item>
                <Dropdown.Item active={isActive("min_baths")} onSelect={() => setOrderValues(sortByElements["min_beds"])}>
                    {t("filters.min_baths")}
                </Dropdown.Item>
                <Dropdown.Item active={isActive("max_baths")} onSelect={() => setOrderValues(sortByElements["max_baths"])}>
                    {t("filters.max_baths")}
                </Dropdown.Item>
                <Dropdown.Item active={isActive("min_m2")} onSelect={() => setOrderValues(sortByElements["min_m2"])}>
                    {t("filters.min")} m<sup>2</sup>
                </Dropdown.Item>
                <Dropdown.Item active={isActive("min_build")} onSelect={() => setOrderValues(sortByElements["min_build"])}>
                    {t("filters.min_build")}
                </Dropdown.Item>
                <Dropdown.Item active={isActive("max_build")} onSelect={() => setOrderValues(sortByElements["max_build"])}>
                    {t("filters.max_build")}
                </Dropdown.Item>
                <Dropdown.Item active={isActive("oldest")} onSelect={() => setOrderValues(sortByElements["oldest"])}>
                    {t("filters.oldest")}
                </Dropdown.Item>
            </DropdownButton>
        </div>
    );
}

export default SortByDropdown;