import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

import DescriptionProps from './Description.types'
import './Description.scss';

function Description({ description }: DescriptionProps): JSX.Element {
    const { t } = useTranslation();
    const [showCompleteDescription, setShowCompleteDescription] = useState(false);

    return (
        <div id={"description"}>
            <h4>Description</h4>
            <hr />
            <div className={!showCompleteDescription ? "ellipsis-text" : ""}>
                <p>{description}</p>
                <Button className={"btn btn-link btn-link-blue"} onClick={() => setShowCompleteDescription(!showCompleteDescription)}>
                    {t(showCompleteDescription ? "property.hide_description" : "property.show_description")}
                </Button>
            </div>
        </div>
    );
}

export default Description;