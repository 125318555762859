import React, {useCallback, useContext, useEffect, useLayoutEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {FaChevronDown, FaChevronUp} from "react-icons/all";
import {Button} from "react-bootstrap";

import {SEO} from "../../components/common";
import { ICriteria, IPage } from "../../domain";
import {ToasterContext} from "../../stores/toaster";
import {LoadingContext} from "../../stores/loading";
import PropertyService from "../../service/PropertyService";
import {transformCriteriaToQueryParam, transformQueryParamToCriteria} from "../../utils/utils";
import {AdvancedCriteria, HeaderSale, ResultSale} from "../../components";

import SerchProps from './Search.types';
import './Search.scss';

function Search(props: SerchProps): JSX.Element {
    const { t, i18n } = useTranslation();
    const { dispatch } = useContext(ToasterContext);
    const { dispatch: loader } = useContext(LoadingContext);

    const initPage: IPage = {
        currentPage: 1,
        totalPages: 1,
        totalProperties: 0,
        size: 30,
        properties: []
    };
    const search: string = props?.location?.search || "?page=1&size=30&field=UPDATED&order=DESC&sold=false";

    const [criteria, setCriteria] = useState<ICriteria>(transformQueryParamToCriteria(search));
    const [page, setPage] = useState<IPage | null>(initPage);
    const [showFilters, setShowFilters] = useState<boolean>(true);

    const getProperties = useCallback(async () => {
        try {
            const page: IPage = await PropertyService.getProperties(transformCriteriaToQueryParam(criteria));
            setPage(page);
        } catch (error) {
            loader({show: false});
            dispatch({message: `${t("common.wrong")}`, type: "error"});
        }
    }, [loader, dispatch, t, criteria]);

    useEffect(() => {
        loader({ show: true });
        getProperties().then(() => loader({show: false }));
    }, [i18n.language, getProperties, loader]);

    useEffect(() => {
        setCriteria(transformQueryParamToCriteria(search));
    }, [search]);

    useLayoutEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll)
    });

    const handleScroll = () => {
        if (window.innerWidth <= 992 && showFilters && window.scrollY > 250) {
            setShowFilters(false);
        }
    };

    return (
        <div id={"search-page"} className={"page"}>

            <SEO title={"Search"} />
            <div className={"search-container"}>
                <div id="criteria-block" className={showFilters ? "criteria" : "hide-filters"}>
                    <div className={`filter-headers ${!showFilters ? "without-margin" : ""}`}>
                        <Button className={"btn btn-color btn-color-blue btn-filters"}
                                onClick={() => setShowFilters(!showFilters)}
                        >
                            {t("common.filters")} { showFilters ? <FaChevronUp /> : <FaChevronDown /> }
                        </Button>
                        <HeaderSale criteria={criteria} {...props}/>
                    </div>
                    { showFilters ? <AdvancedCriteria criteria={criteria} {...props}/> : '' }
                </div>
                <div className={"result"}>
                    {page ? <ResultSale page={page} criteria={criteria} {...props}/> : ''}
                </div>
            </div>
        </div>
    );
}

export default Search;