import React, {useContext, useState} from "react";
import { useTranslation } from 'react-i18next';
import { FaPaperPlane } from "react-icons/all";
import { Col, Form, Button } from "react-bootstrap";

import {ToasterContext} from "../../stores/toaster";
import {IContact} from "../../domain";
import ContactService from "../../service/ContactService";

function ContactForm(props: { className?: string, propertyId ?: string }) {
    const { t } = useTranslation();
    const { dispatch } = useContext(ToasterContext);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [sent, setSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const emailValidator = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    function isValidForm(): string {
        if (!name) return t("contact.required.name");
        else if (!email) return t("contact.required.email");
        else if (!email.match(emailValidator)) return t("contact.required.email_valid");
        else if (!message) return t("contact.required.message");
        else return "";
    }

    async function sendEmail() {
        setLoading(true);
        try {
            const contactData: IContact = {
                name,
                email,
                message: `${props.propertyId ? `THIS PERSON CONTACTED YOU ABOUT ${props.propertyId}\n\n LINK: ${window.location.href}\n\n` :""}${message}`
            };
            await ContactService.sendEmail(contactData);
            dispatch({message: t("contact.success_message"), type: "success"});
            initValues();
        } catch (error) {
            setLoading(false);
            dispatch({message: `${t("common.wrong")}: ${error.message}`, type: "error"});
        }
    }

    const initValues = () :void => {
        setName("");
        setEmail("");
        setMessage("");
        setSent(true);
        setLoading(false);
    }

    return (
        <Form className={props?.className}>
            <Form.Row>
                <Form.Group as={Col} sm={12} md={6} controlId="name">
                    <Form.Control
                        required
                        type="text"
                        placeholder={t("contact.name")}
                        value={name}
                        disabled={sent || loading}
                        onChange={e => setName(e.currentTarget.value)}
                    />
                </Form.Group>
                <Form.Group as={Col} sm={12} md={6} controlId="email">
                    <Form.Control
                        required
                        type="email"
                        placeholder={t("contact.email")}
                        value={email}
                        disabled={sent || loading}
                        onChange={e => setEmail(e.currentTarget.value)}
                    />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="message">
                    <Form.Control
                        required
                        as="textarea"
                        rows={5}
                        placeholder={t("contact.message")}
                        value={message}
                        disabled={sent || loading}
                        onChange={e => setMessage(e.currentTarget.value)}
                    />
                </Form.Group>
            </Form.Row>
            {sent
                ? <p>{t("contact.success_message")}</p>
                : <p>{isValidForm()}</p>
            }
            <Form.Row>
                <Form.Group as={Col}>
                    <Button
                        disabled={!name || !email || !email.match(emailValidator) || !message || sent || loading}
                        className={"btn btn-color btn-color-blue float-right"}
                        onClick={sendEmail}
                    >
                        <FaPaperPlane/> { loading ? "Sending email..." : t("contact.send")}
                    </Button>
                </Form.Group>
            </Form.Row>
        </Form>
    );
}

export default ContactForm;