import Axios from "axios";
import {IContact} from "../domain";

const BASE_URL: string = `${process.env.REACT_APP_MAILER_URL}`;
const endpoints = {
    sendEmail: () => `${BASE_URL}/send`,
};

const sendEmail = (data: IContact): Promise<any> => {
    return Axios.post(endpoints.sendEmail(), data)
        .then(res => res)
        .catch(err => { throw err; });
};

export default { sendEmail };
